<template>
  <div>
    <el-date-picker
      type="daterange"
      :picker-options="pickerOptions"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      v-model="value"
      size="small"
      @change="search()"
    />
  </div>
</template>

<script>
export default {
  props: {
    startDate: {
      default: ""
    },
    endDate: {
      default: ""
    }
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      value: []
    };
  },
  computed: {
    start: {
      get() {
        return this.startDate;
      },
      set(val) {
        this.$emit("update:startDate", val);
      }
    },
    end: {
      get() {
        return this.endDate;
      },
      set(val) {
        this.$emit("update:endDate", val);
      }
    }
  },
  methods: {
    search() {
      if (this.value == null) {
        console.log("value", this.value); //这是的value 为 null

        this.start = "";
        this.end = "";
      } else {
        this.start = this.value[0];
        this.end = this.value[1];
        this.$parent.getData();
      }
    }
  }
};
</script>

<style></style>
