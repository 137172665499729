<template>
  <div>
    <div class="search-bar">
      <el-form inline size="small">
        <el-form-item>
          <el-input placeholder="请输入姓名" v-model="params.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="params.deptId" placeholder="所属部门" clearable>
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in departments"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="params.positionId"
            placeholder="所属职位"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in positions"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="params.loginStatus"
            placeholder="最后登录"
            clearable
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in loginOption"
              :key="item.index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button size="small" type="primary" @click="search">
          搜索
        </el-button>
      </el-form>
      <div>
        <span class="tip-label">已绑定员工账号</span>
        <span class="tip-value"> {{ employeeNum }}</span>

        <el-button size="small" type="primary" @click="add">
          添加员工
        </el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table
        header-align="center"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{
          background: '#E9F3FE',
          fontWeight: 'blod'
        }"
      >
        <el-table-column label="编号" width="55" type="index" align="center" />
        <el-table-column prop="name" label="员工姓名" align="center" />
        <el-table-column prop="headImg" label="头像" align="center">
          <template slot-scope="scope">
            <img
              :src="scope.row.headImg"
              style="height:62px;width:62px;border-radius:50%;"
            />
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号" align="center" />
        <el-table-column prop="deptName" label="部门" align="center" />
        <el-table-column prop="positionName" label="职位" align="center" />
        <el-table-column prop="lastLoginTime" label="最后登录" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 'UNBIND'">已主动解绑</span>
            <span v-else>{{
              scope.row.lastLoginTime | formatTime("YYYY-MM-DD")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="bindingTime" label="绑定时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.bindingTime | formatTime("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column prop="bindingTime" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button
              type="text"
              style="color:#F22727"
              @click="del(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :page.sync="params.current"
        :limit.sync="params.size"
        @pagination="getdata"
      />
    </div>

    <el-dialog
      :title="formType == 'add' ? '新增员工' : '编辑员工'"
      :visible.sync="dialogFormVisible"
      width="520px"
      :close-on-click-modal="false"
    >
      <el-form :model="form" ref="form" label-width="100px" :rules="formRule">
        <el-form-item
          label="员工姓名"
          placeholder="请填写员工姓名"
          required
          prop="name"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          label="员工账号"
          placeholder="请填写员工账号"
          required
          prop="mobile"
        >
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="员工部门">
          <el-select v-model="form.deptId" placeholder="请选择部门">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in departments"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工职位">
          <el-select v-model="form.positionId" placeholder="请选择职位">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in positions"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEmployee,
  getDepartments,
  getPosition,
  addEmployee,
  editEmployee,
  delEmployee,
  getEmployeeNum
} from "@/api/dashboard.js";
import Pagination from "@/components/Pagination.vue";
export default {
  components: {
    Pagination
  },
  data() {
    return {
      tableData: [],
      departments: [],
      positions: [],
      total: 0,
      employeeNum: 0,
      formType: "add",
      params: {
        current: 1,
        size: 10,
        deptId: "",
        name: "",
        positionId: ""
      },
      form: {
        deptId: null,
        mobile: "",
        name: "",
        positionId: null
      },
      formRule: {
        name: [{ required: true, message: "请输入员工名称", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "请输入正确手机号",
            trigger: "blur"
          }
        ]
      },
      dialogFormVisible: false,
      loginOption: [
        {
          value: 1,
          label: "登录过"
        },
        {
          value: 0,
          label: "从未登录"
        },
        {
          value: -1,
          label: "主动解绑"
        }
      ]
    };
  },
  methods: {
    async search() {
      this.params.current = 1;
      this.getdata();
    },
    async getdata() {
      const res = await getEmployee(this.params);
      this.tableData = res.records;
      this.total = res.total;
    },
    async getDepartments() {
      const res = await getDepartments({ current: 1, size: 100 });
      this.departments = res.records;
    },
    async getPosition() {
      const res = await getPosition({ current: 1, size: 100 });
      this.positions = res.records;
    },
    async getEmployeeNum() {
      const res = await getEmployeeNum();
      this.employeeNum = res.employeeNum;
    },
    async save() {
      if (this.formType == "add") {
        this.$refs["form"].validate(async valid => {
          if (valid) {
            await addEmployee(this.form);
            this.dialogFormVisible = false;
            this.$message.success("添加成功");
            this.getdata();
          }
        });
      } else {
        this.$refs["form"].validate(async valid => {
          if (valid) {
            await editEmployee(this.form);
            this.dialogFormVisible = false;
            this.$message.success("保存成功");
            this.getdata();
          }
        });
      }
    },
    async edit(row) {
      this.formType = "edit";
      this.dialogFormVisible = true;
      this.form = { ...row };
      this.clearValidate();
    },
    async add() {
      this.formType = "add";
      this.form = { deptId: null, mobile: "", name: "", positionId: null };
      this.dialogFormVisible = true;
      this.clearValidate();
    },
    async del(id) {
      await this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      });
      await delEmployee({ id });
      this.$message({
        type: "success",
        message: "删除成功!"
      });
      this.getdata();
    },
    clearValidate() {
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    }
  },
  created() {
    this.getdata();
    this.getDepartments();
    this.getPosition();
    this.getEmployeeNum();
  }
};
</script>

<style lang="scss" scoped>
.table-container {
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07058823529411765);
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  // margin-top: 20px;
}
.search-bar {
  display: flex;
  justify-content: space-between;
}
.tip-label {
  font-size: 14px;
  color: #999999;
  font-weight: bold;
}
.tip-value {
  margin-left: 8px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #2a8bee;
}
</style>
