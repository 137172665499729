<template>
  <div class="overview">
    <div class="overview-content" @click="goSeriesCourse()">
      <div class="overview-content-icon">
        <img src="@/assets/images/dashboard/icon/创建系列课.png" alt="" />
      </div>
      <div class="overview-content-right">
        <div class="overview-content-header">
          <div class="overview-content-right-data">
            创建系列课
          </div>
          <div class="overview-content-right-item">
            <img src="@/assets/images/dashboard/icon/箭头.png" alt="" />
          </div>
        </div>
        <div class="overview-content-right-item">
          前往创建机构系列课程
        </div>
      </div>
    </div>

    <div class="overview-content" @click="goBroadcastCourse()">
      <div class="overview-content-icon">
        <img src="@/assets/images/dashboard/icon/创建录播课.png" alt="" />
      </div>
      <div class="overview-content-right">
        <div class="overview-content-header">
          <div class="overview-content-right-data">
            创建录播课
          </div>
          <div class="overview-content-right-item">
            <img src="@/assets/images/dashboard/icon/箭头.png" alt="" />
          </div>
        </div>
        <div class="overview-content-right-item">
          前往创建机构录播课程
        </div>
      </div>
    </div>

    <div class="overview-content ">
      <div class="overview-content-icon">
        <img src="@/assets/images/dashboard/icon/敬请期待2.png" alt="" />
      </div>
      <div class="overview-content-right">
        <div class="overview-content-header">
          <div class="overview-content-right-data">
            敬请期待
          </div>
          <div class="overview-content-right-item">
            <img src="@/assets/images/dashboard/icon/箭头.png" alt="" />
          </div>
        </div>
        <div class="overview-content-right-item">
          快捷入口新功能
        </div>
      </div>
    </div>

    <div class="overview-content ">
      <div class="overview-content-icon">
        <img src="@/assets/images/dashboard/icon/敬请期待1.png" alt="" />
      </div>
      <div class="overview-content-right">
        <div class="overview-content-header">
          <div class="overview-content-right-data">
            敬请期待
          </div>
          <div class="overview-content-right-item">
            <img src="@/assets/images/dashboard/icon/箭头.png" alt="" />
          </div>
        </div>
        <div class="overview-content-right-item">
          快捷入口新功能
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    goSeriesCourse() {
      this.$router.push({ name: "seriesCourse", query: { create: 1 } });
    },
    goBroadcastCourse() {
      this.$router.push({ name: "broadcastCourse", query: { create: 1 } });
    }
  }
};
</script>

<style lang="scss" scoped>
.overview {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;

  &-content {
    display: flex;
    align-items: center;
    margin: 17px 5px;
    width: 375px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0);
    box-sizing: content-box;
    cursor: pointer;

    &-icon {
      display: flex;
      img {
        width: 120px;
      }
    }

    &-header {
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
    }

    &-right {
      &-data {
        font-size: 16px;
        font-weight: bold;
        color: #404040;
        padding-right: 10px;
      }

      &-item {
        font-size: 14px;
        font-weight: 400;
        color: #cccccc;

        img {
          width: 17px;
        }
      }
    }
  }
}
</style>
