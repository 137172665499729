<template>
  <div>
    <div class="header">
      <div class="header-left">
        <div></div>
        <span>店铺数据</span>
      </div>
      <!-- <div class="header-right" @click="openMore()">
        <span>查看更多</span>
        <i class="el-icon-d-arrow-right"></i>
      </div> -->
    </div>
    <ShopOverView />
    <div class="header" style="margin-bottom:2px">
      <div class="header-left">
        <div></div>
        <span>运营快捷入口</span>
      </div>
    </div>
    <QuickEntrance />
    <div class="header" style="margin-bottom:19px">
      <div class="header-left">
        <div></div>
        <span>课程总览</span>
      </div>
      <div class="header-right" @click="$router.push({ name: 'seriesCourse' })">
        <span>查看更多</span>
        <i class="el-icon-d-arrow-right"></i>
      </div>
    </div>
    <CourseList />
  </div>
</template>

<script>
import ShopOverView from "./ShopOverView.vue";
import QuickEntrance from "./QuickEntrance.vue";
import CourseList from "./CourseList";
export default {
  components: {
    ShopOverView,
    QuickEntrance,
    CourseList
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  &-left {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #404040;
    opacity: 1;
  }

  &-left:first-child div {
    width: 4px;
    height: 16px;
    background: rgba(42, 139, 238, 1);
    opacity: 1;
    border-radius: 6px;
    margin-right: 10px;
  }

  &-right {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
  }
}
</style>
