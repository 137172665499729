<template>
  <div class="changeRole">
    <div class="container">
      <div class="changeRole-text"><span>请选择要进入的工作台版本</span></div>
      <div class="changeRole-English">
        <span>Please select the workbench version to enter</span>
      </div>
      <div class="changeRole-button">
        <div class="changeRole-button-item" @click="changeRole('ORGANIZATION')">
          <img src="@/assets/images/dashboard/img/编组@2x.png" alt="" />
        </div>
        <div class="changeRole-button-item" @click="changeRole('COMPANY')">
          <img src="@/assets/images/dashboard/img/编组 5@2x.png" alt="" />
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import { changeUserRole } from "../../api/dashboard";
import Foot from "../layout/Foot.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Foot
  },
  methods: {
    ...mapActions(["getUserInfo"]),
    ...mapGetters(["userInfo"]),
    async changeRole(role) {
      console.log(role);
      await changeUserRole({ userRole: role });
      await this.getUserInfo();
      if (role == "COMPANY") {
        this.$router.push("/index");
      } else {
        this.$router.push("/organization_index");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  min-height: calc(100vh - 120px);
}

.changeRole {
  background-image: url(../../assets/images/dashboard/img/loginBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  margin: auto;
  text-align: center;

  &-text {
    margin: auto;
    text-align: center;
    padding-top: 150px;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
  }

  &-English {
    margin: auto;
    text-align: center;
    padding-top: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    opacity: 0.5;
  }

  &-button {
    display: flex;
    justify-content: center;
    padding-top: 120px;
    img {
      width: 450px;
    }

    &-item {
      cursor: pointer;
    }
  }
}
</style>
