<template>
  <div>
    <div class="header" style="margin-bottom:19px" v-show="display">
      <div class="header-left">
        <div class="header-left-icon"></div>
        <div class="header-left-search">
          <el-form :model="params" inline size="small">
            <el-form-item label="员工学习时长详情" class="item">
              <el-input
                v-model="params.name"
                placeholder="请输入姓名"
                size="small"
                clearable
              ></el-input>
            </el-form-item>
            <el-button
              icon="el-icon-search"
              size="small"
              type="primary"
              plain
              @click="search"
            >
              搜索
            </el-button>
          </el-form>
        </div>
      </div>
      <div class="header-right">
        <DatePicker
          :startDate.sync="params.startDate"
          :endDate.sync="params.endDate"
        />
      </div>
    </div>
    <div class="table">
      <el-table
        header-align="center"
        :data="studyData"
        style="width: 100%"
        :header-cell-style="{
          background: '#E9F3FE',
          fontWeight: 'blod'
        }"
      >
        <el-table-column label="序号" width="55" type="index" align="center" />
        <el-table-column prop="name" label="员工姓名" align="center" />
        <el-table-column prop="bindingTime" label="绑定企业时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.bindingTime | formatTime("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="平台账号" align="center" />
        <el-table-column
          prop="dayStudyDurationStr"
          label="今日学习时长"
          align="center"
        />
        <el-table-column
          prop="studyDurationSumStr"
          label="学习总时长"
          align="center"
        />
        <el-table-column prop="bindingTime" label="绑定时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.bindingTime | formatTime("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column prop="lastLoginTime" label="最近登录" align="center">
          <template slot-scope="scope">
            {{ scope.row.lastLoginTime | formatTime("YYYY-MM-DD") }}
          </template></el-table-column
        >
      </el-table>
      <Pagination
        :total="total"
        :page.sync="params.current"
        :limit.sync="params.size"
        :layout="'total, prev, pager, next'"
        @pagination="getData()"
        style="padding-bottom:18px"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Pagination from "@/components/Pagination.vue";
import DatePicker from "../../components/DatePicker.vue";
export default {
  props: {
    display: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Pagination,
    DatePicker
  },
  data() {
    return {
      pageShow: true,
      params: {
        current: 1,
        size: 10,
        name: "",
        startDate: "",
        endDate: ""
      }
    };
  },
  computed: {
    ...mapState("dashboard", ["studyData", "total"])
  },
  methods: {
    ...mapActions("dashboard", ["getStudyDetailData"]),
    getData() {
      console.log("params", this.params);
      this.getStudyDetailData(this.params);
    },
    search() {
      // this.pageShow = false;
      this.params.current = 0;
      this.getData();
      // this.$$nextTick(() => {
      //   this.pageShow = true;
      // });
    }
  },
  created() {
    this.getData();
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-left {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #404040;
    opacity: 1;

    &-icon {
      width: 4px;
      height: 16px;
      background: rgba(42, 139, 238, 1);
      opacity: 1;
      border-radius: 6px;
      margin-right: 10px;
    }

    &-search {
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      align-items: center;
    }
  }
}

.table {
  background: #fff;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

::v-deep {
  .item .el-form-item__label {
    font-size: 16px;
    font-weight: bold;
    color: #404040;
    opacity: 1;
  }

  .el-form-item--small.el-form-item {
    margin-bottom: 0px;
    margin-left: -11px;
  }
}
</style>
