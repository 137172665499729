<template>
  <div class="overview">
    <div class="overview-header">
      <div class="overview-header-left">
        <div></div>
        <span>员工数据总览</span>
      </div>
      <div class="overview-header-right">
        <DatePicker
          :startDate.sync="params.startDate"
          :endDate.sync="params.endDate"
        />
      </div>
    </div>
    <div class="overview-bg">
      <div class="overview-content">
        <div class="overview-content-icon">
          <img src="@/assets/images/icon/icon-01.png" alt="" />
        </div>
        <div class="overview-content-right">
          <div>
            <div class="overview-content-header">
              <div class="overview-content-right-data">
                {{ data.accountNum }}
              </div>
              <div class="overview-content-right-item">
                个
              </div>
            </div>
            <div class="overview-content-right-item">
              员工账号总数
            </div>
          </div>
        </div>
      </div>
      <div class="overview-line"></div>
      <div class="overview-content">
        <div class="overview-content-icon">
          <img src="@/assets/images/icon/icon-02.png" alt="" />
        </div>
        <div class="overview-content-right">
          <div class="overview-content-header">
            <div class="overview-content-right-data">
              {{ data.dayStudyEmployeeNum }}
            </div>
            <div class="overview-content-right-item">
              人
            </div>
          </div>
          <div class="overview-content-right-item">
            今日学习员工
          </div>
        </div>
      </div>
      <div class="overview-line"></div>
      <div class="overview-content">
        <div class="overview-content-icon">
          <img src="@/assets/images/icon/icon-03.png" alt="" />
        </div>
        <div class="overview-content-right">
          <div class="overview-content-header">
            <div class="overview-content-right-data">
              --
            </div>
            <div class="overview-content-right-item">
              个
            </div>
          </div>
          <div class="overview-content-right-item">
            已购企业课程
          </div>
        </div>
      </div>
      <div class="overview-line"></div>
      <div class="overview-content">
        <div class="overview-content-icon">
          <img src="@/assets/images/icon/icon-04.png" alt="" />
        </div>
        <div class="overview-content-right">
          <div class="overview-content-header">
            <div class="overview-content-right-data">
              --
            </div>
            <div class="overview-content-right-item">
              个
            </div>
          </div>
          <div class="overview-content-right-item">
            已购直播课程
          </div>
        </div>
      </div>
      <div class="overview-line"></div>
      <div class="overview-content">
        <div class="overview-content-icon">
          <img src="@/assets/images/icon/icon-05.png" alt="" />
        </div>
        <div class="overview-content-right">
          <div class="overview-content-header">
            <div class="overview-content-right-data">
              {{ data.avgStudyDurationStr }}
            </div>
            <div class="overview-content-right-item">
              分
            </div>
          </div>
          <div class="overview-content-right-item">
            人均
          </div>
        </div>
      </div>
      <div class="overview-line"></div>
      <div class="overview-content">
        <div class="overview-content-icon">
          <img src="@/assets/images/icon/icon-06.png" alt="" />
        </div>
        <div class="overview-content-right">
          <div class="overview-content-header">
            <div class="overview-content-right-data">
              {{ data.dayStudyDurationStr }}
            </div>
            <div class="overview-content-right-item">
              分
            </div>
          </div>
          <div class="overview-content-right-item">
            今日学习时长
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DatePicker from "../../../components/DatePicker.vue";
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      params: {
        current: 1,
        size: 11,
        name: "",
        startDate: "",
        endDate: ""
      }
    };
  },
  computed: {
    ...mapState("dashboard", ["data"])
  },
  methods: {
    ...mapActions(["getUserInfo"]),
    ...mapActions("dashboard", ["getDashboardData"]),
    getData() {
      this.getDashboardData(this.params);
    }
  },
  created() {
    this.getUserInfo();
    this.getData();
  }
};
</script>

<style lang="scss" scoped>
.overview {
  margin-bottom: 26px;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    &-left {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #404040;
      opacity: 1;
    }

    &-left:first-child div {
      width: 4px;
      height: 16px;
      background: rgba(42, 139, 238, 1);
      opacity: 1;
      border-radius: 6px;
      margin-right: 10px;
    }

    &-right {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  &-bg {
    background-image: url("../../../assets/images/background/viewbg@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 36px 39px 36px 29px;

    &-icon {
      display: flex;
      margin-right: 30px;
      img {
        width: 44px;
      }
    }
    &-right {
      &-data {
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
        padding-right: 6px;
      }

      &-item {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        padding-bottom: 5px;
      }
    }

    &-header {
      display: flex;
      align-items: flex-end;
    }
  }

  &-line {
    width: 0px;
    height: 37px;
    opacity: 0.27;
    color: #ffffff;
    border: 1px solid #ffffff;
    margin: auto 0;
  }
}
</style>
