<template>
  <div>
    <div class="search-bar">
      <el-form inline size="small">
        <el-form-item>
          <el-input v-model="params.name" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-button size="small" type="primary" @click="search">
          搜索
        </el-button>
      </el-form>
      <div>
        <el-button size="small" type="primary" @click="add">
          添加职位
        </el-button>
      </div>
    </div>
    <div>
      <el-row :gutter="20">
        <el-col :span="6" v-for="item in tableData" :key="item.id">
          <div class="card-container">
            <div class="card-container-header">
              <div class="card-container-header-content">
                <div class="card-container-header-name">
                  <span>{{ item.name }}</span>
                </div>
                <div style="float:right">
                  <el-button
                    style="color:#fff"
                    type="text"
                    icon="el-icon-edit-outline"
                    @click="edit(item)"
                  ></el-button>
                  <el-button
                    type="text"
                    icon="el-icon-delete"
                    style="color:#fff"
                    @click="del(item.id)"
                  ></el-button>
                </div>
              </div>
            </div>
            <div class="card-container-content">
              <div>
                职位人数
              </div>
              <div style="color: #2a8cf0">
                {{ item.personNumOfPosition }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <Pagination
        :total="total"
        :page.sync="params.current"
        :limit.sync="params.size"
        @pagination="getdata"
      />
    </div>

    <el-dialog
      :title="formType == 'add' ? '新增职位' : '编辑职位'"
      :visible.sync="dialogFormVisible"
      width="520px"
      :close-on-click-modal="false"
    >
      <el-form :model="form" ref="form" label-width="100px" :rules="formRule">
        <el-form-item
          label="职位姓名"
          placeholder="请填写职位姓名"
          required
          prop="name"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPosition,
  addPosition,
  editPosition,
  delPosition
} from "@/api/dashboard.js";
import Pagination from "@/components/Pagination.vue";
export default {
  components: {
    Pagination
  },
  data() {
    return {
      tableData: [],
      departments: [],
      positions: [],
      total: 0,
      formType: "add",
      params: {
        name: "",
        current: 1,
        size: 10
      },
      form: {
        name: ""
      },
      formRule: {
        name: [{ required: true, message: "请输入职位名称", trigger: "blur" }]
      },
      dialogFormVisible: false
    };
  },
  methods: {
    async search() {
      this.params.current = 1;
      this.getdata();
    },
    async getdata() {
      const res = await getPosition(this.params);
      this.tableData = res.records;
      this.total = res.total;
    },
    async getPosition() {
      const res = await getPosition({ current: 1, size: 100 });
      this.positions = res.records;
    },
    async save() {
      if (this.formType == "add") {
        this.$refs["form"].validate(async valid => {
          if (valid) {
            await addPosition(this.form);
            this.dialogFormVisible = false;
            this.$message.success("添加成功");
            this.getdata();
          }
        });
      } else {
        this.$refs["form"].validate(async valid => {
          if (valid) {
            await editPosition(this.form);
            this.dialogFormVisible = false;
            this.$message.success("保存成功");
            this.getdata();
          }
        });
      }
    },
    async edit(row) {
      this.formType = "edit";
      this.dialogFormVisible = true;
      this.form = { ...row };
      this.clearValidate();
    },
    async add() {
      this.formType = "add";
      this.form = { name: "" };
      this.dialogFormVisible = true;
      this.clearValidate();
    },
    async del(id) {
      await this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      });
      await delPosition({ id });
      this.$message({
        type: "success",
        message: "删除成功!"
      });
      this.getdata();
    },
    clearValidate() {
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    }
  },
  created() {
    this.getdata();
  }
};
</script>

<style lang="scss" scoped>
.card-container {
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(235, 235, 235, 0.44313725490196076);
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  margin-bottom: 20px;
  &-header {
    padding: 0 20px;
    height: 48px;
    background: #2a8bee;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    line-height: 48px;
    &-content {
      display: flex;
      justify-content: space-between;
    }

    &-name {
      display: flex;
      span {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  &-content {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 69px;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 69px;
  }
}
.search-bar {
  display: flex;
  justify-content: space-between;
}
</style>
