<template>
  <div>
    <img src="@/assets/images/dashboard/price/price.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
