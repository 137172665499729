<template>
  <div style="display: flex">
    <div style="min-width: 280px">
      <div class="title">课程类型</div>
      <div class="course-type">
        <ul>
          <li
            :class="{
              active: item.id === params.id
            }"
            v-for="item in types"
            :key="item.id"
            @click="handlerCilck(item.id)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <div class="course-list">
      <div class="title">
        课程列表
        <span style="float: right">
          <el-form :model="params" inline size="small">
            <el-form-item>
              <el-select v-model="params.courseType" placeholder="类型筛选">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="params.shelfStatus" placeholder="状态筛选">
                <template v-if="this.params.id == 40 || this.params.id == 42">
                  <el-option
                    v-for="item in freeStatusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
                <template v-if="this.params.id == 41">
                  <el-option
                    v-for="item in tollStatusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-bottom: 0">
              <el-input
                v-model="params.keyword"
                placeholder="请输入关键字"
                size="small"
                clearable
              ></el-input>
            </el-form-item>
            <el-button
              icon="el-icon-search"
              size="small"
              type="primary"
              plain
              @click="search"
            >
              搜索
            </el-button>
          </el-form>
        </span>
      </div>
      <div class="course-list-card">
        <div class="table-container">
          <el-table
            header-align="center"
            :data="tableData"
            :key="Math.random()"
            style="width: 100%"
            :header-cell-style="{
              background: '#E9F3FE',
              fontWeight: 'blod'
            }"
          >
            <el-table-column
              label="编号"
              width="55"
              type="index"
              align="center"
            />
            <el-table-column prop="liveImg" label="课程封面" align="center">
              <template slot-scope="scope">
                <img :src="scope.row.liveImg" alt="" style="height: 80px" />
              </template>
            </el-table-column>
            <el-table-column prop="liveTitle" label="课程信息" align="center" />

            <el-table-column
              prop="price"
              label="价格信息"
              align="center"
              v-if="params.id !== 40"
            >
              <template slot-scope="scope">
                原价：{{ scope.row.price }} <br />
                现价：{{ scope.row.discountPrice }} <br />
                有效期：{{ getDeadLine(scope.row.deadLine) }}<br />
                销量：{{ scope.row.sales }}<br />
              </template>
            </el-table-column>

            <el-table-column
              prop="liveBeginTime"
              label="课程时间"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.liveBeginTime | formatTime("YYYY-MM-DD HH:mm") }}
              </template></el-table-column
            >
            <el-table-column prop="livePlayPv" label="点击量" align="center" />
            <el-table-column prop="chapterNum" label="集数" align="center" />
            <el-table-column prop="liveStatus" label="课程类型" align="center">
              <template slot-scope="scope">
                {{ courseStatusMap(scope.row.liveStatus) }}
              </template>
            </el-table-column>
            <el-table-column prop="shelfStatus" label="状态" align="center">
              <template slot-scope="scope">
                <div class="type">
                  <div class="type-content">
                    <div
                      class="type-circle"
                      :style="{
                        background:
                          scope.row.shelfStatus == 1 ? '#3CD384' : '#C25454 '
                      }"
                    ></div>
                    <span> {{ shelfStatusMap(scope.row.shelfStatus) }} </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="preview(scope.row)"
                  >查看</el-button
                >
                <el-button
                  type="text"
                  @click="shelfSwitch(scope.row)"
                  :key="scope.row.id + '-' + scope.row.shelfStatus"
                  :style="{
                    color: scope.row.shelfStatus == 2 ? '#3CD384' : '#2A8BEE'
                  }"
                >
                  {{ scope.row.shelfStatus == 2 ? "上架" : "下架" }}
                </el-button>
                <el-button
                  v-if="params.id !== 41"
                  type="text"
                  style="color: #f22727"
                  @click="del(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :page.sync="params.current"
            :limit.sync="params.size"
            @pagination="getData()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import {
  courseStatusMap,
  deadLine,
  bizTypeMap,
  shelfStatusMap
} from "../../filters/index";
import {
  getCourseType,
  getCourse,
  delCourseType,
  courseTypeshelfSwitch
} from "@/api/dashboard.js";
import { formatDate } from "@/utils/index.js";

export default {
  components: {
    Pagination
  },
  data() {
    return {
      shelfStatusMap,
      courseStatusMap,
      deadLine,
      params: {
        keyword: "",
        courseType: "",
        shelfStatus: "",
        id: 0,
        current: 1,
        size: 10
      },
      types: [],
      total: 0,
      tableData: [],
      typeOptions: [
        {
          value: null,
          label: "全部"
        },
        {
          value: "4",
          label: "录播课"
        },
        {
          value: "5",
          label: "系列课"
        },
        {
          value: "0",
          label: "即将开播"
        },
        {
          value: "1",
          label: "直播中"
        },
        {
          value: "2",
          label: "直播结束"
        },
        {
          value: "3",
          label: "回放"
        }
      ],
      // 免费状态
      freeStatusOptions: [
        {
          value: null,
          label: "全部"
        },
        {
          value: "1",
          label: "已上架"
        },
        {
          value: "2",
          label: "已下架"
        }
      ],
      // 收费状态
      tollStatusOptions: [
        {
          value: null,
          label: "全部"
        },
        {
          value: "1",
          label: "已上架"
        },
        {
          value: "2",
          label: "已下架"
        },
        {
          value: "3",
          label: "已过期"
        }
      ]
    };
  },

  methods: {
    async getCourseType() {
      this.types = await getCourseType();
      this.params.id = this.types[0]?.id;
      await this.getData();
    },
    handlerCilck(id) {
      this.params.courseType = "";
      this.params.shelfStatus = "";
      this.params.keyword = "";
      this.params.current = 1;
      this.params.id = id;
      this.getData();
    },
    async getData() {
      const res = await getCourse(this.params);
      this.tableData = res.records;
      this.total = res.total;
    },
    search() {
      this.params.current = 1;
      this.getData();
    },
    preview(row) {
      this.$router.push({
        path: "/coursemarket/mediacenter",
        query: {
          id: row.bizId,
          courseType: bizTypeMap(row.bizType)
        }
      });
    },
    async shelfSwitch(row) {
      await courseTypeshelfSwitch({
        id: row.id,
        shelfStatus: row.shelfStatus == 1 ? 2 : 1
      });
      this.$message.success("操作成功");
      this.getData();
    },
    async del(id) {
      await this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      });
      await delCourseType({ id });
      this.$message({
        type: "success",
        message: "删除成功!"
      });
      this.getData();
    },
    getDeadLine(time) {
      if (time == null || time == 0) {
        return "永久";
      } else {
        let date = new Date(time);
        return formatDate(date, "yyyy-MM-dd hh:mm:ss");
      }
    }
  },
  created() {
    this.getCourseType();
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  line-height: 25px;
  height: 30px;
  &::before {
    display: inline-block;
    content: " ";
    width: 4px;
    height: 16px;
    background: #2a8bee;
    border-radius: 6px;
    margin-right: 9px;
  }
}
.course-type {
  background: #ffffff;
  border-radius: 4px;
  min-height: 50vh;
  margin-top: 10px;
  ul {
    padding: 20px;
  }
  li {
    cursor: pointer;
    height: 36px;
    list-style: none;
    margin: 10px 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 36px;
    border-radius: 4px;
    text-align: center;
    &:hover,
    &.active {
      background-color: #e9f6ff;
      color: #2a8bee;
    }
  }
}
.course-list {
  clear: both;
  margin-left: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &-card {
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    flex-grow: 1;
  }
}

.type {
  display: flex;

  &-content {
    display: flex;
    margin: auto;
    text-align: center;
    align-items: center;
  }

  &-circle {
    width: 11px;
    height: 11px;
    border-radius: 50px;
    margin-right: 5px;
  }
}
</style>
