<template>
  <div class="course-list-card">
    <div>
      <el-input
        size="small"
        style="width: 200px;margin-right: 20px;"
        v-model.trim="params.keyword"
        placeholder="请输入课程名称"
      >
      </el-input>
      <el-button
        size="small"
        @click="search"
        icon="el-icon-search"
        type="primary"
        plain
      >
        查询
      </el-button>
      <el-button
        size="small"
        style="float: right;"
        @click="add"
        icon="el-icon-plus"
        type="primary"
      >
        新增课程
      </el-button>
    </div>

    <div class="table-container">
      <el-table
        ref="table"
        header-align="center"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{
          background: '#E9F3FE',
          fontWeight: 'blod'
        }"
        sortable
        row-key="id"
      >
        <el-table-column label="编号" width="55" type="index" align="center" />
        <el-table-column prop="liveTitle" label="课程名称" align="center">
        </el-table-column>

        <el-table-column prop="updateTime" label="更新时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.updateTime | formatTime("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="时长" align="center">
          <template slot-scope="scope">
            {{ getHMS(scope.row.duration) }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="preview(scope.row)">预览</el-button>
            <el-button
              type="text"
              style="color:#F22727"
              @click="del(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :page.sync="params.current"
        :limit.sync="params.size"
        @pagination="getData"
      />
    </div>
    <el-dialog
      width="500px"
      title="新增系列课"
      :visible.sync="dialogVisible"
      @open="clearValidate"
      top="30px"
    >
      <el-form
        size="small"
        :model="form"
        ref="form"
        label-width="100px"
        :rules="formRule"
      >
        <el-form-item label="课程名称" prop="subCourseName" required>
          <el-input
            v-model.trim="form.subCourseName"
            maxlength="30"
            show-word-limit
            placeholder="请填写课程名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="上传视频" required prop="videoId">
          <div class="clearfix videoUpload">
            <div class="clearfix">
              <a href="javascript:;" class="file"
                >选择文件
                <input
                  id="videoUpload"
                  ref="videoUpload"
                  name="videoUpload"
                  type="file"
                  accept="video/mp4"
                  @change="videoChange($event)"
                />
              </a>
              <p>{{ fileName }}</p>
              <p class="fl tips3">
                *仅支持仅支持mp4格式视频文件，建议视频时间在90分钟内
              </p>
            </div>

            <p class="status">
              上传状态: <span>{{ statusText }}</span>
            </p>
            <el-button :disabled="uploadDisabled" @click="authUpload()">
              开始上传
            </el-button>
            <el-button :disabled="pauseDisabled" @click="pauseUpload()">
              暂停
            </el-button>
            <el-button :disabled="resumeDisabled" @click="resumeUpload()">
              恢复上传 </el-button
            ><br />
          </div>
          <div class="progress clearfix">
            <div class="fl auth-progress">
              <p :style="{ width: authProgress + '%' }" />
            </div>
            <p class="fl percent">
              {{ authProgress + "%" }}
            </p>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 预览 -->
    <el-dialog
      title="预览"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="proview"
      width="600px"
      top="0"
      @close="proview = false"
    >
      <video
        v-if="proview"
        controls="controls"
        :src="src"
        style="width: 500px"
      />
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  getSubCourse,
  addSubCourse,
  editCourse,
  delSeries,
  shelfSwitch,
  sortCourse
} from "@/api/dashboard.js";
import { createUploadVideo, refreshUploadVideo } from "@/api/index.js";
import { getHMS } from "../../../filters/index";
import Sortable from "sortablejs";

export default {
  components: {
    Pagination
  },
  data() {
    return {
      getHMS,
      tableData: [],
      params: {
        current: 1,
        size: 10,
        courseType: 5,
        keyword: null
      },
      form: {
        seriesCourseId: null,
        subCourseName: null,
        videoId: null
      },
      formRule: {
        subCourseName: [
          { required: true, message: "请输入课程名称", trigger: "blur" }
        ],
        videoId: [{ required: true, message: "请上传视频", trigger: "blur" }]
      },
      total: 0,
      dialogVisible: false,
      formType: "add",
      timeout: "",
      partSize: "",
      parallel: "",
      retryCount: "",
      retryDuration: "",
      statusText: "",
      region: "oss-cn-beijing",
      userId: "1303984639806000",
      file: null,
      authProgress: 0,
      uploadDisabled: true,
      resumeDisabled: true,
      pauseDisabled: true,
      uploader: null,
      uploadInfo: {},
      proview: false,
      src: "",
      fileName: "" //上传视频原名
    };
  },
  methods: {
    async getData() {
      const res = await getSubCourse({
        id: this.$route.query.courseId,
        keyword: this.params.keyword,
        current: this.params.current,
        size: this.params.size
      });
      this.tableData = res.records;
      this.total = res.total;
    },
    search() {
      this.params.current = 1;
      this.getData();
    },
    async add() {
      this.formType = "add";
      this.form = {
        seriesCourseId: this.$route.query.courseId,
        subCourseName: null,
        videoId: null
      };
      this.authProgress = 0;
      this.file = null;
      this.fileName = "";
      this.statusText = "";
      this.uploadDisabled = true;
      this.dialogVisible = true;
      // this.clearValidate();
    },
    async save() {
      if (this.formType == "add") {
        this.$refs["form"].validate(async valid => {
          if (valid) {
            await addSubCourse(this.form);
            this.dialogVisible = false;
            this.$message.success("添加成功");
            this.getData();
          }
        });
      } else {
        this.$refs["form"].validate(async valid => {
          if (valid) {
            await editCourse(this.form);
            this.dialogVisible = false;
            this.$message.success("保存成功");
            this.getData();
          }
        });
      }
    },
    async del(id) {
      await this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      });
      await delSeries(id);
      this.$message({
        type: "success",
        message: "删除成功!"
      });
      this.getData();
    },
    clearValidate() {
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    edit(row) {
      this.formType = "edit";
      this.dialogVisible = true;
      this.form = {
        ...row
      };

      // this.clearValidate();
    },
    editSubCourse(row) {
      this.$router.push({
        name: "seriesSubCourse",
        courseId: row.id
      });
    },
    preview(row) {
      // this.getList();
      this.proview = true;
      this.src = row.streamUrl;
    },
    status(status) {
      switch (status) {
        case 0:
          return "即将开播";
        case 1:
          return "直播";
        case 2:
          return "直播";
        case 3:
          return "回放";
        default:
          return "系列课";
      }
    },
    async shelfSwitch(row) {
      await shelfSwitch({
        courseId: row.id,
        status: row.shelfStatus ^ 1
      });
      this.$message.success("操作成功");
      this.getData();
    },
    videoChange(e) {
      this.file = e.target.files[0];
      let strArr = this.file.name.split(".");
      console.log(1111, strArr[strArr.length - 1]);
      if (!this.file) {
        this.$message.error("请先选择需要上传的文件!");
        return;
      }
      // strArr[strArr.length - 1] != "m3u8" &&
      //   strArr[strArr.length - 1] != "flv" &&
      if (
        strArr[strArr.length - 1] != "mp4" &&
        strArr[strArr.length - 1] != "MP4"
      ) {
        this.$message.error("请上传正确的文件格式!");
        return;
      }

      var userData = '{"Vod":{}}';
      if (this.uploader) {
        this.uploader.stopUpload();
        this.authProgress = 0;
        this.statusText = "";
      }
      this.uploader = this.createUploader();
      console.log(userData);
      this.uploader.addFile(this.file, null, null, null, userData);
      this.uploadDisabled = false;
      this.pauseDisabled = true;
      this.resumeDisabled = true;
    },
    // 开始上传
    authUpload() {
      if (!this.form.subCourseName) {
        this.$message.error("请输入标题", 1200);
        return false;
      }
      // 然后调用 startUpload 方法, 开始上传
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.uploadDisabled = true;
        this.pauseDisabled = false;
      }
    },
    // 暂停上传
    pauseUpload() {
      if (this.uploader !== null) {
        this.uploader.stopUpload();
        this.resumeDisabled = false;
        this.pauseDisabled = true;
      }
    },
    // 恢复上传
    resumeUpload() {
      if (!this.form.subCourseName) {
        this.$message.error("请输入标题", 1200);
        return false;
      }
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.resumeDisabled = true;
        this.pauseDisabled = false;
      }
    },
    createUploader() {
      let self = this;
      let uploader = new window.AliyunUpload.Vod({
        timeout: self.timeout || 60000,
        partSize: self.partSize || 1048576,
        parallel: self.parallel || 5,
        retryCount: self.retryCount || 3,
        retryDuration: self.retryDuration || 2,
        region: self.region,
        userId: self.userId,
        // 添加文件成功
        addFileSuccess: function(uploadInfo) {
          self.uploadDisabled = false;
          self.resumeDisabled = false;
          self.statusText = "添加文件成功, 等待上传...";
          this.fileName = uploadInfo.file.name;
          console.log("addFileSuccess: " + uploadInfo.file.name);
        },
        // 开始上传
        onUploadstarted: function(uploadInfo) {
          console.log("开始上传", uploadInfo);
          // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
          // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
          // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
          // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
          // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
          // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
          if (!uploadInfo.videoId) {
            createUploadVideo({
              title: self.form.subCourseName,
              fileName: uploadInfo.file.name
            }).then(data => {
              let uploadAuth = data.uploadAuth;
              let uploadAddress = data.uploadAddress;
              let videoId = data.videoId;
              uploader.setUploadAuthAndAddress(
                uploadInfo,
                uploadAuth,
                uploadAddress,
                videoId
              );
            });
            self.statusText = "文件开始上传...";
            console.log(
              "1111onUploadStarted:" +
                uploadInfo.file.name +
                ", endpoint:" +
                uploadInfo.endpoint +
                ", bucket:" +
                uploadInfo.bucket +
                ", object:" +
                uploadInfo.object
            );
          } else {
            // 如果videoId有值，根据videoId刷新上传凭证
            // https://help.aliyun.com/document_detail/55408.html?spm=a2c4g.11186623.6.630.BoYYcY
            refreshUploadVideo(uploadInfo.videoId).then(data => {
              let uploadAuth = data.uploadAuth;
              let uploadAddress = data.uploadAddress;
              let videoId = data.videoId;
              uploader.setUploadAuthAndAddress(
                uploadInfo,
                uploadAuth,
                uploadAddress,
                videoId
              );
            });
          }
        },
        // 文件上传成功
        onUploadSucceed: function(uploadInfo) {
          self.statusText = "文件上传完毕";
          self.uploadInfo = uploadInfo;
          self.form.videoId = uploadInfo.videoId;
        },
        // 文件上传失败
        onUploadFailed: function() {
          self.statusText = "文件上传失败!";
        },
        // 取消文件上传
        onUploadCanceled: function() {
          self.statusText = "文件已暂停上传";
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: function(uploadInfo, totalSize, progress) {
          let progressPercent = Math.ceil(progress * 100);
          self.authProgress = progressPercent;
          self.statusText = "文件上传中...";
        },
        // 上传凭证超时
        onUploadTokenExpired: function(uploadInfo) {
          // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
          // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
          // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
          refreshUploadVideo(uploadInfo.videoId).then(({ data }) => {
            let uploadAuth = data.uploadAuth;
            uploader.resumeUploadWithAuth(uploadAuth);
            console.log(
              "upload expired and resume upload with uploadauth " + uploadAuth
            );
          });
          self.statusText = "文件超时...";
        },
        // 全部文件上传结束
        onUploadEnd: function() {
          console.log("onUploadEnd: uploaded all the files");
          self.statusText = "文件上传完毕";
        }
      });
      return uploader;
    },
    //行拖拽
    sortableInit() {
      const tbody = this.$refs.table.$el.querySelector("tbody");
      const mainBox = document.querySelector(".mainbox");
      const self = this;
      Sortable.create(tbody, {
        delay: 100,
        scroll: mainBox,
        scrollSensitivity: 150,
        scrollSpeed: 20,
        animation: 500,
        chosenClass: "sortable-ghost",
        ghostClass: "sortable-ghost",
        dragClass: "sortable-ghost",
        easing: "cubic-bezier(0.34, 1.56, 0.64, 1)",
        onEnd({ newIndex, oldIndex }) {
          const currRow = self.tableData.splice(oldIndex, 1)[0];
          self.tableData.splice(newIndex, 0, currRow);
          console.log("tuozhuaihou", self.tableData);
          const ids = self.tableData.map(i => i.id);
          console.log(ids);
          sortCourse({ ids }).then(() => {
            self.$message.success("操作成功");
            self.getData();
          });
        }
      });
    }
  },
  mounted() {
    this.sortableInit();
  },
  created() {
    if (!this.$route.query.courseId) {
      this.$message.warning("参数错误");
      return;
    }
    this.form.seriesCourseId = this.$route.query.courseId;
    this.getData();
  }
};
</script>

<style lang="scss" scoped>
.tips {
}
.dialog-footer {
}
.table-container {
  margin-top: 10px;
}

.file {
  background: #e6f1fc;
  border-radius: 4px;
  border: 1px solid #a3d0fd;
  position: relative;
  display: inline-block;
  padding: 4px 12px;
  overflow: hidden;
  color: #1989fa;
  text-decoration: none;
  text-indent: 0;
  line-height: 20px;
  cursor: pointer;
}
.file input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
}
</style>
