<template>
  <div class="overview">
    <div class="overview-content color1">
      <div class="overview-content-icon">
        <img src="@/assets/images/icon/icon-01.png" alt="" />
      </div>
      <div class="overview-content-right">
        <div class="overview-content-header">
          <div class="overview-content-right-data">
            {{ data.accountNum }}
          </div>
          <div class="overview-content-right-item">
            个
          </div>
        </div>
        <div class="overview-content-right-item">
          员工账号总数
        </div>
      </div>
    </div>

    <div class="overview-content color2">
      <div class="overview-content-icon">
        <img src="@/assets/images/icon/icon-02.png" alt="" />
      </div>
      <div class="overview-content-right">
        <div class="overview-content-header">
          <div class="overview-content-right-data">
            {{ data.dayStudyEmployeeNum }}
          </div>
          <div class="overview-content-right-item">
            人
          </div>
        </div>
        <div class="overview-content-right-item">
          今日学习员工
        </div>
      </div>
    </div>

    <div class="overview-content color3">
      <div class="overview-content-icon">
        <img src="@/assets/images/icon/icon-03.png" alt="" />
      </div>
      <div class="overview-content-right">
        <div class="overview-content-header">
          <div class="overview-content-right-data">
            --
          </div>
          <div class="overview-content-right-item">
            个
          </div>
        </div>
        <div class="overview-content-right-item">
          已购企业课程
        </div>
      </div>
    </div>

    <div class="overview-content color4">
      <div class="overview-content-icon">
        <img src="@/assets/images/icon/icon-04.png" alt="" />
      </div>
      <div class="overview-content-right">
        <div class="overview-content-header">
          <div class="overview-content-right-data">
            --
          </div>
          <div class="overview-content-right-item">
            个
          </div>
        </div>
        <div class="overview-content-right-item">
          已购直播课程
        </div>
      </div>
    </div>

    <div class="overview-content color3">
      <div class="overview-content-icon">
        <img src="@/assets/images/icon/icon-05.png" alt="" />
      </div>
      <div class="overview-content-right">
        <div class="overview-content-header">
          <div class="overview-content-right-data">
            {{ data.avgStudyDurationStr }}
          </div>
          <div class="overview-content-right-item">
            分
          </div>
        </div>
        <div class="overview-content-right-item">
          人均
        </div>
      </div>
    </div>

    <div class="overview-content color5">
      <div class="overview-content-icon">
        <img src="@/assets/images/icon/icon-06.png" alt="" />
      </div>
      <div class="overview-content-right">
        <div class="overview-content-header">
          <div class="overview-content-right-data">
            {{ data.dayStudyDurationStr }}
          </div>
          <div class="overview-content-right-item">
            分
          </div>
        </div>
        <div class="overview-content-right-item">
          今日学习时长
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("dashboard", ["data"])
  },
  methods: {
    ...mapActions(["getUserInfo"]),
    ...mapActions("dashboard", ["getDashboardData"])
  },
  created() {
    // this.getUserInfo();
    this.getDashboardData();
  }
};
</script>

<style lang="scss" scoped>
.overview {
  display: flex;
  flex-wrap: wrap;
  .color1 {
    background: linear-gradient(180deg, #8dc6ff 0%, #3377ff 100%);
    box-shadow: 0px 4px 8px 0px rgba(60, 165, 255, 0.3);
    border-radius: 8px;
  }
  .color2 {
    background: linear-gradient(180deg, #ffca8d 0%, #ffb833 100%);
    box-shadow: 0px 4px 8px 0px rgba(255, 90, 60, 0.3);
    border-radius: 8px;
  }

  .color3 {
    background: linear-gradient(180deg, #8dc2ff 0%, #00ceff 100%);
    box-shadow: 0px 4px 8px 0px rgba(60, 165, 255, 0.3);
    border-radius: 8px;
  }

  .color4 {
    background: linear-gradient(180deg, #55eaa5 0%, #11ad4d 100%);
    box-shadow: 0px 4px 8px 0px rgba(60, 165, 255, 0.3);
    border-radius: 8px;
  }

  .color5 {
    background: linear-gradient(180deg, #8dc2ff 0%, #00ceff 100%);
    box-shadow: 0px 4px 8px 0px rgba(60, 165, 255, 0.3);
    border-radius: 8px;
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 27px 36px 30px 30px;
    margin: 17px 12px;
    width: 180px;
    box-sizing: content-box;

    &-icon {
      display: flex;
      img {
        width: 44px;
      }
    }
    &-right {
      &-data {
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
        padding-right: 6px;
      }

      &-item {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        padding-bottom: 5px;
      }
    }

    &-header {
      display: flex;
      align-items: flex-end;
    }
  }
}
</style>
