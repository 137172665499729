<template>
  <div class="course-list-card">
    <div class="title">课程类型</div>
    <el-form
      size="small"
      :model="form"
      ref="form"
      label-width="100px"
      :rules="formRule"
      label-position="left"
      style="margin-top: 19px"
    >
      <!-- 第一步，填写基本信息表单框-->
      <div>
        <el-form-item label="课程名称" prop="liveTitle" required>
          <el-input
            v-model.trim="form.liveTitle"
            maxlength="30"
            show-word-limit
            placeholder="请填写课程名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="封面" prop="liveImg" required>
          <ImageField v-model="form.liveImg"> </ImageField>
        </el-form-item>
        <el-form-item label="课程简介" prop="liveDescription" required>
          <el-input
            v-model.trim="form.liveDescription"
            type="textarea"
            placeholder="请填写课程简介"
            maxlength="150"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="主讲人" prop="liveLecturer" required>
          <el-input
            v-model.trim="form.liveLecturer"
            maxlength="11"
            placeholder="请填写主讲人"
          ></el-input>
        </el-form-item>
        <el-form-item label="直播时间" prop="liveBeginTime" required>
          <el-date-picker
            v-if="form.courseType < 2"
            v-model="form.liveBeginTime"
            type="datetime"
            placeholder="选择日期时间"
            :picker-options="afterToday"
            @change="logtime()"
          >
          </el-date-picker>
          <el-date-picker
            v-else
            v-model="form.liveBeginTime"
            type="datetime"
            placeholder="选择日期时间"
            @change="logtime()"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="点击量设置" prop="livePlayCountSetted" required>
          <el-input-number
            v-model="form.livePlayCountSetted"
            :min="0"
            :max="99999999"
            label="请输入"
          ></el-input-number>
        </el-form-item>
      </div>
      <div class="title">直播设置</div>
      <!-- 第二步，选择直播方式-->
      <div style="margin-top: 10px" v-if="this.editForm.courseType != 1">
        <div>
          <p class="way-notice">请选择一种直播方式</p>
          <p class="third-way-notice">填写第三方拉流地址，直播不会生成回放</p>
        </div>
        <el-radio-group v-model="form.liveWay" size="mini">
          <el-radio label="本账号直播" border>本账号直播</el-radio>
          <el-radio label="设置直播讲师" border>设置直播讲师</el-radio>
          <el-radio label="第三方拉流" border>第三方拉流地址</el-radio>f
        </el-radio-group>
        <div v-show="form.liveWay == '设置直播讲师'">
          <el-radio-group v-model="form.secondWayType" size="mini">
            <el-radio-button :label="1">手机号添加</el-radio-button>
            <!-- <el-radio-button :label="2">微信邀请</el-radio-button> -->
          </el-radio-group>
          <div v-show="form.secondWayType == 1">
            <div v-if="!secondWayResult">
              <el-form-item label="手机号添加">
                <el-input
                  v-model="lecturerInfo.phone"
                  placeholder="请输入对方手机号"
                  class="phone-input"
                ></el-input>
                <el-button type="primary" @click="getLecturerByPhone" plain
                  >添加</el-button
                >
              </el-form-item>
              <el-descriptions title="最近添加" :colon="false">
                <el-descriptions-item>
                  <ul>
                    <li v-for="item in lecturerLog" :key="item.id">
                      <div>
                        <el-image
                          style="width: 30px; height: 30px"
                          :src="item.headImg"
                        ></el-image>
                      </div>
                      <div style="width: 250px; margin-left: 100px">
                        <span>{{ item.username }}</span>
                      </div>
                      <div style="width: 50px">
                        <el-button
                          type="text"
                          size="mini"
                          @click="addLecturer(item)"
                          >添加</el-button
                        >
                      </div>
                    </li>
                  </ul>
                </el-descriptions-item>
              </el-descriptions>
            </div>
            <div v-if="secondWayResult" class="lecturer">
              <div v-if="lecturerInfo.lecturerUserId != 0">
                <el-image
                  style="width: 30px; height: 30px"
                  :src="lecturerInfo.lecturerAvatar"
                ></el-image>
                <span>{{ lecturerInfo.lecturerName }}</span>
                <el-button
                  type="text"
                  size="mini"
                  style="margin-left: 50px"
                  @click="changeLecturer"
                  >更换讲师</el-button
                >
              </div>
            </div>
          </div>
          <div v-show="form.secondWayType == 2">
            <el-form-item label="链接邀请">
              <el-input
                id="urlMobile"
                placeholder="发送邀请信息给好友-成为讲师"
                class="link-input"
                v-model="urlMobile"
              ></el-input>
              <!-- <span id="urlMobile">{{urlMobile}}</span> -->
              <el-button
                type="primary"
                data-clipboard-action="copy"
                data-clipboard-target="#urlMobile"
                @click="copy"
                class="copyButton"
                plain
                >复制链接</el-button
              >
            </el-form-item>
            <el-form-item label="扫码邀请">
              <div id="qrCode" ref="qrCodeDiv"></div>
              <p class="click-notice">*请先点击完成后再扫码</p>
            </el-form-item>
          </div>
        </div>
        <div v-show="form.liveWay == '第三方拉流'">
          <el-form-item label="拉流地址">
            <el-input
              v-model="form.liveM3u8"
              placeholder="请输入拉流地址"
            ></el-input>
            <div class="sub-title">仅支持M3U8格式</div>
          </el-form-item>
        </div>
      </div>
      <div v-else>
        <p class="third-way-notice">正在直播，不能修改</p>
      </div>
    </el-form>
    <div style="padding-left: 60%">
      <el-button type="info" @click="close">取消</el-button>
      <el-button type="primary" @click="save">完成</el-button>
    </div>
  </div>
</template>

<script>
import store from "@/store/index";
import ImageField from "@/components/ImageField";
import {
  editLiveCourse,
  getLecturerByPhone,
  getLecturerLogById,
  lecturerInvitation,
} from "@/api/dashboard.js";
import { createUploadVideo, refreshUploadVideo } from "@/api/index.js";
import { shelfStatusMap } from "@/filters/index";
import QRCode from "qrcodejs2";
import Clipboard from "clipboard";
export default {
  components: {
    ImageField,
  },
  props: {
    editForm: {
      type: Object,
      required: true,
    },
  },
  watch: {
    "form.secondWayType": {
      handler(newValue, oldValue) {
        if (newValue == 2 && oldValue == 1) {
          document.getElementById("qrCode").innerHTML = "";
          this.invitation(this.editForm.id);
        }
      },
    },
  },
  data() {
    return {
      lecturerLog: [],
      lecturerInfo: {
        lecturerUserId: 0,
        lecturerAvatar:
          "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        lecturerName: "",
        phone: "",
      },
      liveDescription: "",
      shelfStatusMap,
      form: {
        liveBeginTime: "",
        liveDescription: "",
        liveImg: "",
        liveLecturer: "",
        livePlayCountSetted: "",
        liveTitle: "",
        id: 0,
        liveWay: "本账号直播",
        secondWayType: 1,
        phone: "",
        lecturerUserId: 0,
        liveM3u8: "",
      },
      formRule: {
        liveDescription: [
          { required: true, message: "请输入课程简介", trigger: "blur" },
        ],
        liveImg: [{ required: true, message: "请上传封面", trigger: "blur" }],
        liveLecturer: [
          { required: true, message: "请输入主讲人", trigger: "blur" },
        ],
        liveLogoImg: [
          { required: true, message: "请上传封面", trigger: "blur" },
        ],
        livePlayCountSetted: [
          { required: true, message: "请设置点击量", trigger: "blur" },
        ],
        liveTitle: [
          { required: true, message: "请输入课程名称", trigger: "blur" },
        ],
        videoId: [{ required: true, message: "请上传视频", trigger: "blur" }],
        time: [{ required: true, message: "请选择直播时间", trigger: "blur" }],
      },
      total: 0,
      formType: "edit",
      timeout: "",
      partSize: "",
      parallel: "",
      retryCount: "",
      retryDuration: "",
      statusText: "",
      region: "oss-cn-beijing",
      userId: "1303984639806000",
      file: null,
      authProgress: 0,
      uploadDisabled: true,
      resumeDisabled: true,
      pauseDisabled: true,
      uploader: null,
      uploadInfo: {},
      shelfStatusOptions: [
        {
          label: "全部",
          value: null,
        },
        {
          label: "已上架",
          value: 1,
        },
        {
          label: "已下架",
          value: 2,
        },
      ],
      dialogTitle: "编辑直播课程",
      time: "",
      qrCodeUrl:
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      colon: false,
      liveWays: ["本账号直播", "设置直播讲师", "第三方拉流"],
      secondWayResult: false,
      invitationId: 0,
      afterToday: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
    };
  },

  computed: {
    urlMobile() {
      return (
        window.location.origin +
        "/h5/lecturer?invitationId=" +
        this.invitationId
      );
    },
  },

  methods: {
    next() {
      if (this.formType == "edit") {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.active = 1;
          } else {
            this.active = 0;
          }
        });
      }
    },
    logtime() {
      var date = new Date(this.form.liveBeginTime);
      this.form.liveBeginTime = date.getTime();
      let nowTime = new Date().getTime();
      if(nowTime >= this.form.liveBeginTime && nowTime - this.form.liveBeginTime <= 5000){
        this.form.liveBeginTime += 4*60*1000
      }
    },
    async save() {
      if (this.formType == "edit") {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            //后端要求：直播方式变化时将其它方式的数据置为null
            if(this.form.liveWay == "本账号直播"){
              this.form.liveLecturerMobile = null
              this.form.liveM3u8 = null
            }else if(this.form.liveWay == "设置直播讲师" && this.form.secondWayType == 1){
              this.form.liveM3u8 = null
            }else if(this.form.liveWay == "设置直播讲师" && this.form.secondWayType == 2){
              this.form.liveLecturerMobile = null
              this.form.liveM3u8 = null
              //this.form.lecturerUserId = 0
            }else if(this.form.liveWay == "第三方拉流"){
              this.form.liveLecturerMobile = null
            }
            await editLiveCourse(this.form).then(() => {
              this.$message.success("修改成功");
              this.close();
            });
          }
        });
      }
    },
    clearValidate() {
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    videoChange(e) {
      this.file = e.target.files[0];
      let strArr = this.file.name.split(".");
      console.log(1111, strArr[strArr.length - 1]);
      if (!this.file) {
        this.$message.error("请先选择需要上传的文件!");
        return;
      }
      if (
        strArr[strArr.length - 1] != "mp4" &&
        strArr[strArr.length - 1] != "m3u8" &&
        strArr[strArr.length - 1] != "flv" &&
        strArr[strArr.length - 1] != "MP4"
      ) {
        this.$message.error("请上传正确的文件格式!");
        return;
      }

      var userData = '{"Vod":{}}';
      if (this.uploader) {
        this.uploader.stopUpload();
        this.authProgress = 0;
        this.statusText = "";
      }
      this.uploader = this.createUploader();
      console.log(userData);
      this.uploader.addFile(this.file, null, null, null, userData);
      this.uploadDisabled = false;
      this.pauseDisabled = true;
      this.resumeDisabled = true;
    },
    // 开始上传
    authUpload() {
      if (!this.form.liveTitle) {
        this.$message.error("请输入标题", 1200);
        return false;
      }
      // 然后调用 startUpload 方法, 开始上传
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.uploadDisabled = true;
        this.pauseDisabled = false;
      }
    },
    // 暂停上传
    pauseUpload() {
      if (this.uploader !== null) {
        this.uploader.stopUpload();
        this.resumeDisabled = false;
        this.pauseDisabled = true;
      }
    },
    // 恢复上传
    resumeUpload() {
      if (!this.form.liveTitle) {
        this.$message.error("请输入标题", 1200);
        return false;
      }
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.resumeDisabled = true;
        this.pauseDisabled = false;
      }
    },
    createUploader() {
      let self = this;
      let uploader = new window.AliyunUpload.Vod({
        timeout: self.timeout || 60000,
        partSize: self.partSize || 1048576,
        parallel: self.parallel || 5,
        retryCount: self.retryCount || 3,
        retryDuration: self.retryDuration || 2,
        region: self.region,
        userId: self.userId,
        // 添加文件成功
        addFileSuccess: function (uploadInfo) {
          self.uploadDisabled = false;
          self.resumeDisabled = false;
          self.statusText = "添加文件成功, 等待上传...";
          console.log("addFileSuccess: " + uploadInfo.file.name);
        },
        // 开始上传
        onUploadstarted: function (uploadInfo) {
          console.log("开始上传", uploadInfo);
          // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
          // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
          // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
          // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
          // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
          // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
          if (!uploadInfo.videoId) {
            createUploadVideo({
              title: self.form.liveTitle,
              fileName: uploadInfo.file.name,
            }).then((data) => {
              let uploadAuth = data.uploadAuth;
              let uploadAddress = data.uploadAddress;
              let videoId = data.videoId;
              uploader.setUploadAuthAndAddress(
                uploadInfo,
                uploadAuth,
                uploadAddress,
                videoId
              );
            });
            self.statusText = "文件开始上传...";
            console.log(
              "1111onUploadStarted:" +
                uploadInfo.file.name +
                ", endpoint:" +
                uploadInfo.endpoint +
                ", bucket:" +
                uploadInfo.bucket +
                ", object:" +
                uploadInfo.object
            );
          } else {
            // 如果videoId有值，根据videoId刷新上传凭证
            // https://help.aliyun.com/document_detail/55408.html?spm=a2c4g.11186623.6.630.BoYYcY
            refreshUploadVideo(uploadInfo.videoId).then((data) => {
              let uploadAuth = data.uploadAuth;
              let uploadAddress = data.uploadAddress;
              let videoId = data.videoId;
              uploader.setUploadAuthAndAddress(
                uploadInfo,
                uploadAuth,
                uploadAddress,
                videoId
              );
            });
          }
        },
        // 文件上传成功
        onUploadSucceed: function (uploadInfo) {
          self.statusText = "文件上传完毕";
          self.uploadInfo = uploadInfo;
          self.form.videoId = uploadInfo.videoId;
        },
        // 文件上传失败
        onUploadFailed: function () {
          self.statusText = "文件上传失败!";
        },
        // 取消文件上传
        onUploadCanceled: function () {
          self.statusText = "文件已暂停上传";
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: function (uploadInfo, totalSize, progress) {
          let progressPercent = Math.ceil(progress * 100);
          self.authProgress = progressPercent;
          self.statusText = "文件上传中...";
        },
        // 上传凭证超时
        onUploadTokenExpired: function (uploadInfo) {
          // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
          // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
          // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
          refreshUploadVideo(uploadInfo.videoId).then(({ data }) => {
            let uploadAuth = data.uploadAuth;
            uploader.resumeUploadWithAuth(uploadAuth);
            console.log(
              "upload expired and resume upload with uploadauth " + uploadAuth
            );
          });
          self.statusText = "文件超时...";
        },
        // 全部文件上传结束
        onUploadEnd: function () {
          console.log("onUploadEnd: uploaded all the files");
          self.statusText = "文件上传完毕";
        },
      });
      return uploader;
    },
    close() {
      this.$emit("close");
    },
    //根据手机号获取讲师
    getLecturerByPhone() {
      getLecturerByPhone(this.lecturerInfo.phone,this.editForm.id).then((res) => {
        console.log("res",res)
        if (res == null) {
          this.$message.warning("该讲师不存在！");
        } else {
          this.lecturerInfo.lecturerUserId = res.id;
          this.lecturerInfo.lecturerName = res.username;
          this.lecturerInfo.lecturerAvatar = res.headImg;
          this.form.lecturerUserId = res.id;
          this.secondWayResult = true;
        }
      });
    },
    addLecturer(row) {
      this.lecturerInfo.lecturerUserId = row.id;
      this.lecturerInfo.lecturerName = row.username;
      this.lecturerInfo.lecturerAvatar = row.headImg;
      this.form.lecturerUserId = row.id;
      this.secondWayResult = true;
    },
    changeLecturer() {
      this.secondWayResult = false;
    },
    initQrCode() {
      new QRCode(this.$refs.qrCodeDiv, {
        text: this.urlMobile,
        width: 100,
        height: 100,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    copy() {
      var clipboard = new Clipboard(".copyButton");
      console.log(clipboard);
      clipboard.on("success", () => {
        this.$message({ message: "复制成功", type: "success" });
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$message({ message: "该浏览器不支持复制", type: "warning" });
        clipboard.destroy();
      });
    },
    invitation(liveId) {
      lecturerInvitation({ liveId: liveId }).then((res) => {
        this.invitationId = res;
        this.initQrCode();
      });
    },
  },
  mounted() {
    //this.sortableInit();
    if (this.editForm.liveWay == null) {
      this.editForm.liveWay = "本账号直播";
    } else if (
      this.editForm.liveWay == "设置直播讲师" &&
      this.editForm.liveLecturer != ""
    ) {
      this.lecturerInfo.phone = this.editForm.liveLecturerMobile;
      this.getLecturerByPhone();
      this.secondWayResult = true;
    }
    this.form = Object.assign(this.form, this.editForm);
    //获取最近添加的讲师
    getLecturerLogById(store.getters.userInfo.id).then((res) => {
      console.log(res);
      this.lecturerLog = res;
    });
  },
  created() {
    // this.getData();
  },
};
</script>

<style lang="scss" scoped>
.phone-input {
  width: 250px;
  margin-right: 30px;
}
.link-input {
  width: 250px;
  margin-right: 30px;
}
.step-button {
  text-align: center;
  margin-top: 34px;
  margin: auto 0;
}
p {
  padding: 5px;
}
.way-notice {
  height: 16px;
  font-size: 16px;
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-weight: 500;
  color: #404040;
  line-height: 16px;
}
.third-way-notice {
  height: 14px;
  font-size: 14px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #909399;
  line-height: 14px;
}
.click-notice{
  padding: 10px 0 0;
  height: 14px;
  font-size: 14px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #909399;
  line-height: 14px;
}
.sub-title {
  color: #909090;
  font-size: 12px;
}
.title {
  position: relative;
  padding-left: 10px;
  font-size: 18px;
  &::before {
    position: absolute;
    display: block;
    content: "";
    height: 15px;
    width: 4px;
    border-radius: 2px;
    background-color: #2a8bee;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.lecturer div {
  padding: 10px 20px;
  vertical-align: middle;
  & span {
    margin-left: 50px;
    width: 200px;
  }
  & .el-button {
    position: relative;
    margin-right: 0px;
  }
}
::v-deep {
  .el-step__head.is-process {
    color: #1989fa;
    border-color: #1989fa;
  }
  .el-step__title.is-process {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 50px;
  }
  .el-step__head.is-success {
    color: #1989fa;
    border-color: #1989fa;
  }
  .el-step__title.is-success {
    font-size: 14px;
    font-weight: 400;
    color: #737373;
    line-height: 50px;
  }
  .el-step__description.is-success {
    color: #1989fa;
  }

  .el-step__title.is-wait {
    font-size: 14px;
    font-weight: 400;
    color: #737373;
    line-height: 50px;
  }
  .el-radio-group {
    margin: 10px auto;
  }
  ul > li {
    list-style: none;
  }
  ul > li > div {
    height: 50px;
    vertical-align: middle;
    float: left;
  }
}
</style>
