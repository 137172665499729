<template>
  <div>
    <OverView />
    <div class="header">
      <div class="header-left">
        <div></div>
        <span>员工学习时长</span>
      </div>
      <div class="header-right">
        <DatePicker
          :startDate.sync="params.startDate"
          :endDate.sync="params.endDate"
        />
      </div>
    </div>
    <div>
      <div id="staff-chart"></div>
    </div>
    <StudyList />
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import OverView from "./OverView.vue";
import StudyList from "../../Dashboard/StudyList.vue";
import { getStudyDuration } from "@/api/dashboard.js";
import echarts from "@/plugins/echarts.js";
import moment from "moment";
export default {
  components: {
    DatePicker,
    OverView,
    StudyList
  },
  data() {
    return {
      params: {
        startDate: moment()
          .subtract(7, "d")
          .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
      },
      data: []
    };
  },
  methods: {
    openMore() {
      this.$router.push("/employeeData");
    },
    async getStudyDuration() {
      const res = await getStudyDuration(this.params);
      this.data = res;
      this.initChart();
    },
    getData() {
      this.getStudyDuration();
    },
    initChart() {
      const myChart = echarts.init(document.getElementById("staff-chart"));
      myChart.setOption({
        tooltip: {},
        xAxis: {
          data: this.data.map(i => i.dateStr)
        },
        yAxis: {},
        series: [
          {
            name: "时长",
            type: "bar",
            // data: this.data.map(i => i.hours + "点")
            data: this.data.map(i => i.hours)
          }
        ]
      });
    }
  },
  mounted() {
    this.getStudyDuration();
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-left {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #404040;
    opacity: 1;
  }

  &-left:first-child div {
    width: 4px;
    height: 16px;
    background: rgba(42, 139, 238, 1);
    opacity: 1;
    border-radius: 6px;
    margin-right: 10px;
  }

  &-right {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
  }
}
#staff-chart {
  height: 300px;
}
</style>
