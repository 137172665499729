<template>
  <div>
    <div class="header">
      <div class="header-left">
        <div></div>
        <span>员工数据</span>
      </div>
      <div class="header-right" @click="openMore()">
        <span>查看更多</span>
        <i class="el-icon-d-arrow-right"></i>
      </div>
    </div>
    <OverView />
    <div class="header" style="margin-bottom:26px">
      <div class="header-left">
        <div></div>
        <span>员工学习详情</span>
      </div>
      <div class="header-right" @click="openMore()">
        <span>查看更多</span>
        <i class="el-icon-d-arrow-right"></i>
      </div>
    </div>
    <StudyList :display="false" />
  </div>
</template>

<script>
import OverView from "./OverView.vue";
import StudyList from "./StudyList.vue";
export default {
  components: {
    OverView,
    StudyList
  },

  methods: {
    openMore() {
      this.$router.push("/employeeData");
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-left {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #404040;
    opacity: 1;
  }

  &-left:first-child div {
    width: 4px;
    height: 16px;
    background: rgba(42, 139, 238, 1);
    opacity: 1;
    border-radius: 6px;
    margin-right: 10px;
  }

  &-right {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
  }
}
</style>
