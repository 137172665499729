<template>
  <div>
    <div class="search-bar">
      <el-form :model="params" inline size="small">
        <el-form-item>
          <el-input
            v-model="params.name"
            placeholder="请输入部门名称"
          ></el-input>
        </el-form-item>
        <el-button size="small" type="primary" @click="search">
          搜索
        </el-button>
      </el-form>
      <div>
        <el-button size="small" type="primary" @click="add">
          新增部门
        </el-button>
      </div>
    </div>
    <div>
      <el-row :gutter="24">
        <el-col :span="6" v-for="item in tableData" :key="item.id">
          <div class="card-container">
            <div class="card-container-header">
              <div>
                <span> {{ item.name }}</span>
              </div>
              <div>
                <el-button
                  style="color:#fff"
                  type="text"
                  icon="el-icon-edit-outline"
                  @click="edit(item)"
                ></el-button>
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  style="color:#fff"
                  @click="del(item.id)"
                ></el-button>
              </div>
            </div>
            <div class="card-container-content">
              <div class="card-container-content-item">
                <span>
                  部门主管
                </span>
                <span class="card-container-content-right">
                  {{ item.managerName }}
                </span>
              </div>
              <div class="card-container-content-item">
                <span>
                  部门人数
                </span>
                <span class="card-container-content-item-right">
                  {{ item.personNumOfDept }}
                </span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <Pagination
        :total="total"
        :page.sync="params.current"
        :limit.sync="params.size"
        @pagination="getdata"
      />
    </div>

    <el-dialog
      :title="formType == 'add' ? '新增部门' : '编辑部门'"
      :visible.sync="dialogFormVisible"
      width="520px"
      :close-on-click-modal="false"
      @close="getEmployeeList()"
    >
      <el-form :model="form" ref="form" label-width="100px" :rules="formRule">
        <el-form-item label="部门名称" placeholder="请填写部门姓名" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          label="部门主管"
          placeholder="请选择部门主管"
          prop="managerId"
        >
          <el-select
            v-model="form.managerId"
            filterable
            remote
            reserve-keyword
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in employeeOptions"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDepartments,
  addDepartments,
  editDepartments,
  delDepartments,
  getEmployee
} from "@/api/dashboard.js";
import Pagination from "@/components/Pagination.vue";
export default {
  components: {
    Pagination
  },
  data() {
    return {
      tableData: [],
      departments: [],
      Departmentss: [],
      total: 0,
      formType: "add",
      params: {
        current: 1,
        size: 10,
        name: ""
      },
      form: {
        name: ""
      },
      formRule: {
        name: [{ required: true, message: "请输入部门名称", trigger: "blur" }],
        managerId: [
          { required: true, message: "请添加部门主管", trigger: "change" }
        ]
      },
      dialogFormVisible: false,
      employeeOptions: []
    };
  },
  methods: {
    async search() {
      this.params.current = 1;
      this.getdata();
    },
    async getdata() {
      const res = await getDepartments(this.params);
      this.tableData = res.records;
      this.total = res.total;
    },
    async getDepartments() {
      const res = await getDepartments({ current: 1, size: 100 });
      this.Departmentss = res.records;
    },
    async save() {
      this.getEmployeeList();
      if (this.formType == "add") {
        this.$refs["form"].validate(async valid => {
          if (valid) {
            await addDepartments(this.form);
            this.dialogFormVisible = false;
            this.$message.success("添加成功");
            this.getdata();
          }
        });
      } else {
        this.$refs["form"].validate(async valid => {
          if (valid) {
            await editDepartments(this.form);
            this.dialogFormVisible = false;
            this.$message.success("保存成功");
            this.getdata();
          }
        });
      }
    },
    cancel() {
      this.dialogFormVisible = false;
      this.getEmployeeList();
    },
    async edit(row) {
      this.formType = "edit";
      this.dialogFormVisible = true;
      if (row.managerName == null) {
        this.employeeOptions.push({
          id: row.managerId,
          name: "无"
        });
        console.log(this.employeeOptions);
      }
      this.form = {
        id: row.id,
        name: row.name,
        managerId: row.managerId
      };

      this.clearValidate();
    },
    async add() {
      this.formType = "add";
      this.form = { name: "" };
      this.dialogFormVisible = true;
      this.clearValidate();
    },
    async del(id) {
      await this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      });
      await delDepartments({ id });
      this.$message({
        type: "success",
        message: "删除成功!"
      });
      this.getdata();
    },
    clearValidate() {
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },

    getEmployeeList(query) {
      getEmployee({
        name: query,
        current: 1,
        size: 100
      }).then(res => {
        this.employeeOptions = res.records;
      });
    },

    remoteMethod(query) {
      if (query !== "") {
        this.getEmployeeList(query);
      } else {
        this.employeeOptions = [];
      }
    }
  },
  created() {
    this.getdata();
    this.getEmployeeList();
  }
};
</script>

<style lang="scss" scoped>
.card-container {
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(235, 235, 235, 0.44313725490196076);
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  &-header {
    padding: 0 20px;
    height: 48px;
    background: #2a8bee;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    line-height: 48px;
    display: flex;
    justify-content: space-between;
  }
  &-header:first-child div {
    display: flex;
    span {
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &-content {
    padding: 25px 20px 27px 20px;
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      &-right {
        color: #2a8cf0;
      }
    }
    &-item:first-child {
      padding-bottom: 24px;
    }
  }
}
.search-bar {
  display: flex;
  justify-content: space-between;
}
</style>
