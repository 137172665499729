var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"course-list-card"},[_c('div',[_c('el-input',{staticStyle:{"width":"200px","margin-right":"20px"},attrs:{"size":"small","placeholder":"请输入课程/主页名称"},model:{value:(_vm.params.keyword),callback:function ($$v) {_vm.$set(_vm.params, "keyword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"params.keyword"}}),_c('el-select',{staticStyle:{"width":"200px","margin-right":"20px"},attrs:{"size":"small","placeholder":"请选择"},model:{value:(_vm.params.shelfStatus),callback:function ($$v) {_vm.$set(_vm.params, "shelfStatus", $$v)},expression:"params.shelfStatus"}},_vm._l((_vm.shelfStatusOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-button',{attrs:{"size":"small","icon":"el-icon-search","type":"primary","plain":""},on:{"click":_vm.search}},[_vm._v(" 查询 ")]),_c('el-button',{staticStyle:{"float":"right"},attrs:{"size":"small","icon":"el-icon-plus","type":"primary"},on:{"click":_vm.add}},[_vm._v(" 新增课程 ")])],1),_c('div',{staticClass:"table-container"},[_c('el-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"header-align":"center","data":_vm.tableData,"header-cell-style":{
        background: '#E9F3FE',
        fontWeight: 'blod',
      },"sortable":"","row-key":"id"}},[_c('el-table-column',{attrs:{"label":"编号","width":"55","type":"index","align":"center"}}),_c('el-table-column',{attrs:{"prop":"liveImg","label":"课程封面","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{staticStyle:{"height":"80px"},attrs:{"src":scope.row.liveImg,"alt":""}})]}}])}),_c('el-table-column',{attrs:{"prop":"liveTitle","label":"课程信息","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.liveTitle)+" ")]),_c('div',[_vm._v("主讲人： "+_vm._s(scope.row.liveLecturer))])]}}])}),_c('el-table-column',{attrs:{"prop":"liveBeginTime","label":"课程时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("formatTime")(scope.row.liveBeginTime,"YYYY-MM-DD HH:mm"))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"livePlayCountSetted","label":"点击量","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.livePlayCount)+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("设置： "+_vm._s(scope.row.livePlayCountSetted))])]}}])}),_c('el-table-column',{attrs:{"prop":"courseType","label":"课程类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(_vm.courseStatusMap(scope.row.courseType))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"shelfStatus","label":"状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"type"},[_c('div',{staticClass:"type-content"},[_c('div',{staticClass:"type-circle",style:({
                  background:
                    scope.row.shelfStatus == 1 ? '#3CD384' : '#C25454 ',
                })}),_c('span',[_vm._v(" "+_vm._s(_vm.shelfStatusMap(scope.row.shelfStatus))+" ")])])])]}}])}),_c('el-table-column',{attrs:{"label":"课程简介","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.description(scope.row.liveDescription)}}},[_vm._v(" 查看 ")])]}}])}),_c('el-table-column',{attrs:{"prop":"bindingTime","label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.preview(scope.row)}}},[_vm._v("预览")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.edit(scope.row)}}},[_vm._v("编辑")]),_c('el-button',{key:scope.row.id + '-' + scope.row.shelfStatus,style:({
              color: scope.row.shelfStatus == 2 ? '#3CD384' : '#2A8BEE',
            }),attrs:{"type":"text"},on:{"click":function($event){return _vm.shelfSwitch(scope.row)}}},[_vm._v(" "+_vm._s(scope.row.shelfStatus == 2 ? "上架" : "下架")+" ")]),_c('el-button',{staticStyle:{"color":"#f22727"},attrs:{"type":"text"},on:{"click":function($event){return _vm.del(scope.row.id)}}},[_vm._v("删除")])]}}])})],1),_c('Pagination',{attrs:{"total":_vm.total,"page":_vm.params.current,"limit":_vm.params.size},on:{"update:page":function($event){return _vm.$set(_vm.params, "current", $event)},"update:limit":function($event){return _vm.$set(_vm.params, "size", $event)},"pagination":_vm.getData}})],1),_c('el-dialog',{attrs:{"width":"500px","title":"课程简介","visible":_vm.dialogDescriptionVisible},on:{"update:visible":function($event){_vm.dialogDescriptionVisible=$event}}},[_c('span',[_vm._v(_vm._s(_vm.liveDescription))])]),(_vm.dialogLiveVisible)?_c('div',[_c('el-dialog',{attrs:{"width":"500px","title":"新增直播课程","visible":_vm.dialogLiveVisible},on:{"update:visible":function($event){_vm.dialogLiveVisible=$event}}},[_c('AddCourse',{on:{"close":function($event){return _vm.closeAddDialog()}}})],1)],1):_vm._e(),(_vm.dialogLiveEditVisible)?_c('div',[_c('el-dialog',{attrs:{"width":"500px","title":"编辑直播课程","visible":_vm.dialogLiveEditVisible},on:{"update:visible":function($event){_vm.dialogLiveEditVisible=$event}}},[_c('EditCourse',{attrs:{"editForm":_vm.form},on:{"close":function($event){return _vm.closeEditDialog()}}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }