<template>
  <div style="display:flex">
    <div style="min-width:400px;">
      <div class="title">主页实时预览</div>
      <div class="mobile-container">
        <div class="mobile-box">
          <div class="mobile-preview">
            <iframe
              frameborder="0"
              height="660"
              width="313"
              ref="iframe"
            ></iframe>
          </div>
          <div class="mobile-border"></div>
        </div>
      </div>
    </div>
    <div class="course-list">
      <div class="title">
        主页信息维护
      </div>
      <div class="course-list-card">
        <el-row>
          <el-col :span="2">
            <span class="label">
              主页名称
            </span>
          </el-col>
          <el-col :span="5">
            <el-input
              size="small"
              placeholder="请输入主页名称"
              maxlength="20"
              show-word-limit
              v-model="name"
            >
            </el-input>
          </el-col>
        </el-row>

        <el-row style="margin-top: 30px;">
          <el-col :span="24">
            <span class="label">
              轮播图维护
            </span>
            <span class="tip">
              建议上传尺寸290x120（像素），小于2MB
            </span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;">
          <el-col :span="2"><span> &nbsp; </span> </el-col>
          <el-col :span="22">
            <div class="table-container">
              <el-table
                header-align="center"
                :data="bannerList"
                style="width: 100%"
                :header-cell-style="{
                  background: '#E9F3FE',
                  fontWeight: 'blod'
                }"
              >
                <el-table-column
                  label="编号"
                  width="55"
                  type="index"
                  align="center"
                />
                <el-table-column prop="url" label="图片预览" align="center">
                  <template slot-scope="scope">
                    <img :src="scope.row.url" alt="" style="height:80px" />
                  </template>
                </el-table-column>
                <el-table-column prop="liveStatus" label="操作" align="center">
                  <template slot-scope="scope">
                    <el-upload
                      accept="image/*"
                      v-loading="upLoading"
                      style="display: inline-block; margin-right: 30px;"
                      :show-file-list="false"
                      :before-upload="handleChange(scope.$index)"
                      action="#"
                    >
                      <el-button
                        type="primary"
                        plain
                        size="small"
                        v-loading="upLoading"
                        >点击上传</el-button
                      >
                    </el-upload>
                    <el-button
                      v-if="scope.$index > 0"
                      type="danger"
                      plain
                      size="small"
                      @click="del(scope.$index)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>

              <div class="btn-group">
                <el-button @click="cancel" size="small">
                  取消
                </el-button>
                <el-button @click="save" type="primary" size="small">
                  保存
                </el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getShopInfo, upShopInfo } from "@/api/dashboard.js";
import { getStsAssume } from "@/api/index";
import OSS from "ali-oss";

export default {
  data() {
    return {
      params: {
        needBanner: true,
        needStatistics: false
      },
      name: "",
      shopId: null,
      bannerList: [],
      upLoading: false
    };
  },

  methods: {
    handlerCilck(id) {
      this.params.id = id;
      this.getData();
    },
    async getData() {
      const res = await getShopInfo(this.params);
      this.bannerList = res.bannerList;
      this.name = res.name;
      this.shopId = res.shopId;
      this.loadIframe();
    },
    del(index) {
      this.bannerList.splice(index, 1);
    },
    handleChange(index) {
      return file => {
        const isNotImage = file.type.includes("image");
        // const isImage = file.type === "image/jpeg" || file.type === "image/png";
        // console.log("file.type", file.type);
        console.log("isImage", isNotImage);
        if (!isNotImage) {
          this.$message.error("上传头像图片只能是图片格式!");
          return;
        }
        this.upLoading = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // 确保文件成功获取，base64数据量比较大
        reader.onload = () => {
          getStsAssume().then(res => {
            const imgClient = new OSS({
              region: "oss-cn-beijing",
              // 云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
              accessKeyId: res.AccessKeyId,
              accessKeySecret: res.AccessKeySecret,
              stsToken: res.SecurityToken,
              bucket: "haierzzfb-media"
            });
            const imgUrl = "live/" + new Date().valueOf() + "_" + file.name;
            imgClient
              .put(imgUrl, file)
              .then(res => {
                // this.$emit("input", res.res.requestUrls[0]);
                this.bannerList[index].url = res.res.requestUrls[0];
                this.upLoading = false;
              })
              .catch(err => {
                console.error(err);
                alert();
              });
          });
        };
      };
    },
    cancel() {
      this.getData();
    },
    loadIframe() {
      const url =
        window.location.origin + "/h5/shop?pc_display=1&shopId=" + this.shopId;
      this.$refs.iframe.src = url;
    },
    async save() {
      const { bannerList, name } = this;
      await upShopInfo({ bannerList, name });
      this.$message.success("保存成功");
      this.loadIframe();
    }
  },
  created() {
    this.getData();
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  line-height: 25px;
  height: 30px;
  &::before {
    display: inline-block;
    content: " ";
    width: 4px;
    height: 16px;
    background: #2a8bee;
    border-radius: 6px;
    margin-right: 9px;
  }
}

.mobile-container {
  background: #ffffff;
  padding: 20px 30px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
}
.mobile-box {
  border-radius: 4px;
  height: 717px;
  width: 354px;
  position: relative;
  .mobile-preview {
    padding: 40px 20px;
  }
  .mobile-border {
    background: url("../../assets/images/Phone@2x.png") no-repeat;
    background-size: contain;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
  }
}
.course-list {
  clear: both;
  margin-left: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &-card {
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    flex-grow: 1;
    padding: 40px;
  }
}

.btn-group {
  margin-top: 40px;
  margin-right: 40px;
  float: right;
}

.label {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
  line-height: 30px;
}
.tip {
  font-size: 14px;
  font-weight: 400;
  color: #909399;
  line-height: 30px;
  margin-left: 30px;
}
</style>
